import './../layout/index.scss'

import {graphql} from 'gatsby'
import React from 'react'
import {EventQueryQuery} from 'types/graphtypes'
import {Event} from 'layout/pages/event'
import {loadBlocksFromGraph} from '../blocks/blocks_graph'
import {TemplateType} from 'types/basictypes'
import {createEventInfo} from 'blocks/agenda/agenda'
import {Template} from 'layout/templates/template'
import {previewFragmentToPreviewItem} from 'layout/preview/preview_graph'

const EventPage: React.FC<TemplateType<EventQueryQuery>> = ({
	data,
	pageContext
}) => {
	const {
		page: {
			path: {
				langcode
			},
			field_location,
			field_date,
			field_end_date,
			fields: {
				metaFragment,
				previewFragment
			},
			relationships: {
				field_blocks
			}
		},
		allPagePreview: { relatedFragments }
	} = data

	const preview = previewFragmentToPreviewItem(previewFragment)
	const blocks = loadBlocksFromGraph(field_blocks)
	const eventInfo = createEventInfo({
		date: field_date,
		endDate: field_end_date,
		location: field_location
	})

	return (
		<Template
			preview={preview}
			pageContext={pageContext}
			lang={langcode}
			relatedFragments={relatedFragments}
			relatedJobsFragments={[]}
			content={blocks}
			metaFragment={metaFragment}
		>
			<Event
				blocks={blocks}
				eventInfo={eventInfo}
			/>
		</Template>
	)
}

export default EventPage

export const pageQuery = graphql`
	query EventQuery($slug: String!, $allTags: [String]) {
		page: nodeEvent(fields: {slug: {eq: $slug}}) {
			field_location
			field_date(formatString: "YYYYMMDD")
			field_end_date(formatString: "YYYYMMDD")
			path {
				langcode
			}
			fields {
				metaFragment: meta {...Meta}
				previewFragment: preview {...Preview}
			}
			relationships {
				field_blocks {...Blocks}
			}
		}

		allPagePreview(
			limit: 13
			sort: {fields: date, order: DESC}
			filter: {tags: {in: $allTags}, has_image: {eq: true}}
		) {
			relatedFragments: nodes {
				...Preview
			}
		}
	}
`
